@import './_variable.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './components/menu.css';
@import './components/button.css';
@import './components/tabs.css';
@import './components/card.css';
@import './components/form.css';
@import './components/table.css';
@import './components/dropdown.css';
@import './components/container.css';
@import './components/checkBox.css';

.spin {
  font-size: 24px;
  @apply text-primary;
}

.page-breadcrumb {
  margin-top: -15px;
  margin-bottom: 10px;
}

.btn-primary {
  @apply w-[120px];
}

.search-form .ant-form-item {
  margin-bottom: 16px;
}

.erp-table {
  & .ant-table-thead > tr > th {
    @apply bg-primary text-gray-100 border-b-gray-400 border-0 border-b border-solid;
    @apply font-normal  py-2 px-0 text-center;
  }

  & .ant-table-thead > tr > th.ant-table-column-sort {
    background: #001529cc;
  }
  & .ant-table-thead > tr > th.ant-table-column-has-sorters:hover {
    background: #001529cc;
  }
  & .ant-table-column-sorter {
    color: #fff;
  }
  & .ant-table-column-sorter-up.active {
    color: #fd9b44;
  }
  & .ant-table-column-sorter-down.active {
    color: #fd9b44;
  }
  & .ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #fff;
  }
  & td.ant-table-column-sort {
    background: #ffffff;
  }

  &
    .ant-table-thead
    > tr
    > .ant-table-cell:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    content: '';
    @apply bg-gray-600;
  }
}

.product-table {
  & .ant-table-tbody > tr > td {
    @apply text-xs;
  }

  & .ant-table-thead > tr > th {
    @apply text-xs;
    @apply bg-slate-600 text-gray-100;
    @apply font-normal py-2 px-0 text-center;
  }

  &
    .ant-table-thead
    > tr
    > .ant-table-cell:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    content: '';
    @apply bg-gray-400;
  }
}

.ant-table-wrapper .ant-table-thead > tr > td {
  @apply bg-slate-600 text-gray-100 rounded-tl-md;
}

.detail-divider {
  @apply mt-4 mb-0;
  @apply border-gray-500;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-disabled.ant-input[disabled] {
  @apply text-[#000];
}

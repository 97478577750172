.erp-table {
  & .ant-table-thead > tr > th {
    @apply bg-primary text-gray-100;
    @apply font-normal;
  }
}

.product-table {
  & .ant-table-thead > tr > th {
    @apply bg-slate-600 text-gray-100;
    @apply font-normal py-2 px-0 text-center;
  }
}

.bussinessColumn {
  background: #fffce4;
}
.amountColumn {
  background: #f4ffe8;
}
.table-row-goal {
  background: #f2f4fc !important;
}
.over-goal-with-green {
  color: #0ebc0e;
}
.lower-goal-with-red {
  color: red;
}

.column-gray {
  background: #fafafa !important;
}

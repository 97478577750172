.quotation_checkBox {
  .ant-checkbox-disabled + span {
    @apply text-[#000];
  }
  .ant-checkbox-group {
    flex-wrap: wrap;
    .ant-checkbox-wrapper {
      margin-inline-start: 0;
    }
  }
}

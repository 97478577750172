.search-form .ant-form-item {
  margin-bottom: 16px;
}

.erp-form {
  max-width: 1440px;
  padding: 15px;
  margin: 0 auto 30px;

  & .ant-form-item {
    @apply pb-3;
    margin: 10px auto;
    border-bottom: 2px dotted;
    @apply border-gray-300;

    & .ant-form-item-label > label {
      word-break: break-all;
      @apply text-gray-500;
    }

    & .ant-form-item-label::after {
      content: '|';
      float: right;
      font-size: 20px;
      line-height: 1.8;
      @apply pr-2;
      @apply text-base;
      @apply text-gray-400;
    }
  }

  & .ant-input[disabled] {
    color: black;
  }

  & .ant-input-number-input[disabled] {
    color: black;
    @apply bg-[#f0f2f5];
  }

  & .ant-picker-input > input[disabled] {
    text-align: center;
    color: black;
  }

  & .ant-picker.ant-picker-disabled .ant-picker-suffix {
    display: none;
  }

  & .ant-select-selection-item {
    color: black;
  }
}

.form-header {
  padding: 8px 15px;
  @apply bg-gray-200;
  border-left: 5px solid;
  border-color: #FD9B44;
}

.form-footer {
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100%;
  > button {
    margin: 0 5px;
  }
}

.divider {
  margin: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}


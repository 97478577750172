.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none !important;
}

/* 主要頁面分頁 tab */
.page-tabs {
  & > .ant-tabs-nav {
    @apply bg-[#f5f5f5] pl-1;
    @apply fixed top-[52px] left-0 right-0 z-50  ease-in duration-300;
    @apply border-0 border-b border-solid border-b-gray-300;
  }

  & .ant-tabs-tab {
    @apply bg-[#f5f5f5] cursor-grab;
    color: #fff;
    background: #334454 !important;
    transition: all 0s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }

  & .ant-tabs-tab:hover {
    @apply bg-white cursor-grab;
    color: #fff;
    background: #334454 !important;
  }

  & .ant-tabs-tab.ant-tabs-tab-active {
    background: #fd9b44 !important;
    border-bottom-color: #fd9b44 !important;
    color: #334454 !important;
  }

  & .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-remove {
    color: #334454 !important;
  }

  & .ant-tabs-tab-remove {
    @apply text-white;
  }

  & .ant-tabs-nav-wrap {
    @apply pt-1;
  }

  & .ant-tabs-top > .ant-tabs-nav {
    @apply m-0;
  }

  & .ant-tabs-content-holder {
    @apply overflow-auto min-h-[calc(100vh_-_130px)]  top-[-16px] left-0 right-0 bottom-0 relative;
  }
}

#page-tabs-trigger {
  display: none;

  &:checked ~ div.page-tabs-box > div.page-tabs .ant-tabs-nav {
    left: 80px;
  }

  & ~ div.page-tabs-box > div.page-tabs .ant-tabs-nav {
    left: 200px;
  }
}

/* 詳細頁面分頁 tabs */
.detail-tabs {
  & .ant-tabs-tab {
    min-width: 120px;
    @apply p-3;
    @apply text-gray-600 !important;
    @apply bg-gray-300 !important;
  }

  & .ant-tabs-tab.ant-tabs-tab-active {
    @apply text-white !important;
    @apply bg-[#47505E] !important;
    border-bottom-color: #47505e !important;
    border-radius: 2px;
  }

  & .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 15px;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    @apply text-white !important;
    @apply font-normal !important;
  }

  & .ant-tabs-tab .anticon {
    margin-right: 8px;
  }

  & .ant-tabs-content-holder {
    @apply mt-6;
    @apply p-0;
  }

  & > .ant-tabs-nav {
    left: 0px !important;
    .ant-tabs-nav-wrap {
      @apply w-full justify-center;
    }
  }

  & .ant-tabs-nav-list {
    width: calc(100% - 5px);
    overflow-x: auto;
  }
}
.dashboard-card {
  & .ant-card-head > .ant-tabs {
    & .ant-tabs-tab {
      width: 100%;
      border-radius: 8px 8px 0 0;
      @apply p-3;
      @apply text-gray-600 !important;
      @apply flex items-center justify-center;
      @apply bg-gray-300 !important;
    }

    & .ant-tabs-tab.ant-tabs-tab-active {
      @apply text-white !important;
      @apply bg-[#47505E] !important;
      border-bottom-color: #47505e !important;
    }

    & .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }

    & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      @apply text-white !important;
      @apply font-normal !important;
    }

    & .ant-tabs-tab .anticon {
      margin-right: 8px;
    }

    & .ant-tabs-content-holder {
      @apply mt-6;
      @apply p-0;
      @apply min-h-[auto];
    }

    & > .ant-tabs-nav {
      left: 0px !important;
      .ant-tabs-nav-wrap {
        @apply w-full  justify-center;
      }
    }

    & .ant-tabs-nav-list {
      width: 100%;
      overflow-x: auto;
      @apply flex items-center;
    }
  }
}

.detail-base-info-card {
  &>.info-header {
    @apply mb-2;
    line-height: 32px;

    & .main-info {
      @apply text-lg;
      @apply font-bold;
    }
  }

  &>.info-body {
    @apply p-2;
    @apply border border-solid rounded-sm border-gray-300;
    @apply bg-white;
  }

  & .ant-form-item {
    @apply text-base;
    margin-bottom: 8px;
  }

  & .ant-form-item-label>label {
    @apply text-gray-500;
  }
}
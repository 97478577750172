.root {
  ::-webkit-scrollbar {
    width: 0px;
  }
}

.logo {
  @apply h-[50px];
}

.user-info {
  @apply h-[52px];
}

.trigger {
  @apply text-xl;
  @apply text-[#FD9B44];
  @apply cursor-pointer;
  @apply leading-[52px];
  transition: color 0.3s;
}

.bell {
  @apply text-xl;
  @apply cursor-pointer;
}

.content {
  overflow: initial;
  margin-top: 106px;
}

.footer {
  z-index: 10;
  @apply bg-gray-200;
  @apply text-gray-700;
  @apply h-[20px];
  @apply text-right;
  @apply px-5 py-1 !important;
  @apply border-0 border-t border-solid border-t-gray-300;

  @apply fixed right-0 bottom-0;
  @apply z-40;
}

.divider {
  @apply border-l-gray-300;
}

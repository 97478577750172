.btn-search {
  @apply w-[120px];
}

.btn {
  min-width: 120px;
}

.btn-slate {
  @apply bg-[#6991A7];
  @apply border-[#6991A7];

  &:hover {
    @apply bg-[#89a4b4];
    @apply border-[#89a4b4];
  }

  &:active {
    @apply bg-[#89a4b4];
    @apply border-[#89a4b4];
  }

  &:focus {
    @apply bg-[#89a4b4];
    @apply border-[#89a4b4];
  }
}